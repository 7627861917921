import { Component } from '@angular/core';
import { EnvService } from './env.service';

import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

const INDEX_PARENT = 100;
const INDEX_REMARRIAGE = 55;
const INDEX_CHILD_UNDER_15 = 55;
const INDEX_CHILD_UPPER_14 = 90;

const INCOME_KIND_SALARY = "salary";
const INCOME_KIND_BUSINESS = "business";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: [

    ],
    styles: [
    ]
})

export class AppComponent {

    constructor(private env: EnvService) {

    }

    visitaionShow = false;
    propertyRealEstateShow = false;
    propertyCashShow = false;
    consolationShow = false;

    doc = {
      parentalGuardian: '甲',
      submitter: '甲',
      childSupportPayer: '甲',
      childSupportTransferFee: '甲',
      consolationFeePayer: '甲',
      consolationFeeCommissionPayer: '甲',
      cashPay: '甲',
      cashPayFee: '甲',
      realEstateGetter: '甲',
      realEstateFee: '甲',

      paymentStartDate1: null,
      childSupport1: 0,
      bankName1: '',
      branchName1: '',
      accountNumber1: '',
      childSupportTransferFee1: 0,

      paymentStartDate2: null,
      childSupport2: 0,
      bankName2: '',
      branchName2: '',
      accountNumber2: '',
      childSupportTransferFee2: 0,

      childVisitaionTime: 1,

      husbandName: '',
      husbandAdress: '',
      wifeName: '',
      wifeAdress: '',
      realEstateDay: '',
      cash: 0,
      cashPayDay: '',
      bankName3: '',
      branchName3: '',
      accountNumber3: '',

      consolationFee: 0,
      consolationDate: ''
    };

    trackByFunction(index, item) {
      //if (!item) return null;
      return index;
    }

    incomeKindOblication = INCOME_KIND_SALARY;
    incomeKindRight = INCOME_KIND_SALARY;

    incomeOblication = 560;

    incomeRight: number = 320;

    childCount: number = 2;

    childArray = [5, 2];

    updateChildCount() {
      this.childArray = [];
      if (!this.childCount) {
        this.childCount = 0;
      }
      for (var i = 0; i < this.childCount; i++) {
        if (i == 0)
          this.childArray.push(5);
        else if (i == 1)
          this.childArray.push(2);
        else
          this.childArray.push(0);
      }
    }

    addChildCount() {
      this.childCount++;
      if (this.childCount > 0) {
        if (this.childCount == 1)
          this.childArray.push(5);
        else if (this.childCount == 2)
          this.childArray.push(2);
        else
          this.childArray.push(0);
      }
    }

    minusChildCount() {
      if (this.childCount > 0) {
        this.childCount--;
        this.childArray.splice(-1, 1);
      }
    }

    addChildYearsold(index) {
      this.childArray[index]++;
    }

    minusChildYearsold(index) {
      if (this.childArray[index] > 0) {
        this.childArray[index]--;
      }
    }

    remarriage = false;

    otherOblicationChildren = false;

    otherOblicationChildCount: number = 1;

    otherOblicationChildArray = [0];

    updateOtherOblicationChildCount() {
      this.otherOblicationChildArray = [];
      if (!this.otherOblicationChildCount) {
        this.otherOblicationChildCount = 0;
      }
      for (var i = 0; i < this.otherOblicationChildCount; i++) {
        this.otherOblicationChildArray.push(0);
      }
    }

    addOtherOblicationChildCount() {
      this.otherOblicationChildCount++;
      if (this.otherOblicationChildCount > 0) {
        this.otherOblicationChildArray.push(0);
      }
    }

    minusOtherOblicationChildCount() {
      if (this.otherOblicationChildCount > 0) {
        this.otherOblicationChildCount--;
        this.otherOblicationChildArray.splice(-1, 1);
      }
    }

    addOtherOblicationChildYearsold(index) {
      this.otherOblicationChildArray[index]++;
    }

    minusOtherOblicationChildYearsold(index) {
      if (this.otherOblicationChildArray[index] > 0) {
        this.otherOblicationChildArray[index]--;
      }
    }

    otherRightChildren = false;

    otherRightChildCount: number = 1;

    otherRightChildArray = [0];

    updateOtherRightChildCount() {
      this.otherRightChildArray = [];
      if (!this.otherRightChildCount) {
        this.otherRightChildCount = 0;
      }
      for (var i = 0; i < this.otherRightChildCount; i++) {
        this.otherRightChildArray.push(0);
      }
    }

    addOtherRightChildCount() {
      this.otherRightChildCount++;
      if (this.otherRightChildCount > 0) {
        this.otherRightChildArray.push(0);
      }
    }

    minusOtherRightChildCount() {
      if (this.otherRightChildCount > 0) {
        this.otherRightChildCount--;
        this.otherRightChildArray.splice(-1, 1);
      }
    }

    addOtherRightChildYearsold(index) {
      this.otherRightChildArray[index]++;
    }

    minusOtherRightChildYearsold(index) {
      if (this.otherRightChildArray[index] > 0) {
        this.otherRightChildArray[index]--;
      }
    }

    // 基礎年収の計算
    calcBacicAnnualIncome(annualIncome, isSalaryIncome) {
      var baciAnnualIncome;
      if (isSalaryIncome === true) {
        if (annualIncome <= 100) {
          baciAnnualIncome = annualIncome * 0.42;
        } else if (annualIncome <= 125) {
          baciAnnualIncome = annualIncome * 0.41;
        } else if (annualIncome <= 150) {
          baciAnnualIncome = annualIncome * 0.40;
        } else if (annualIncome <= 250) {
          baciAnnualIncome = annualIncome * 0.39;
        } else if (annualIncome <= 500) {
          baciAnnualIncome = annualIncome * 0.38;
        } else if (annualIncome <= 700) {
          baciAnnualIncome = annualIncome * 0.37;
        } else if (annualIncome <= 850) {
          baciAnnualIncome = annualIncome * 0.36;
        } else if (annualIncome <= 1350) {
          baciAnnualIncome = annualIncome * 0.35;
        } else if (annualIncome <= 2000) {
          baciAnnualIncome = annualIncome * 0.34;
        } else {
          baciAnnualIncome = annualIncome * 0.34;
        }
      } else {
        if (annualIncome <= 421) {
          baciAnnualIncome = annualIncome * 0.52;
        } else if (annualIncome <= 526) {
          baciAnnualIncome = annualIncome * 0.51;
        } else if (annualIncome <= 870) {
          baciAnnualIncome = annualIncome * 0.50;
        } else if (annualIncome <= 975) {
          baciAnnualIncome = annualIncome * 0.49;
        } else if (annualIncome <= 1144) {
          baciAnnualIncome = annualIncome * 0.48;
        } else if (annualIncome <= 1409) {
          baciAnnualIncome = annualIncome * 0.47;
        } else {
          baciAnnualIncome = annualIncome * 0.47;
        }
      }
      return baciAnnualIncome;
    }

    // 子の指数
    calcChildIndex(childArray) {
      var result = 0;
      childArray.forEach(function (childAge) {
        if (childAge < 15) {
          result += INDEX_CHILD_UNDER_15;
        } else if (childAge < 21) {
          result += INDEX_CHILD_UPPER_14;
        }
      });
      return result;
    }

    calcAnnualIncomeRightOtherChildren(bacicAnnualIncome) {
      return bacicAnnualIncome *
        (1 - this.calcChildIndex(this.otherRightChildArray) / (INDEX_PARENT + this.calcChildIndex(this.childArray) + this.calcChildIndex(this.otherRightChildArray)))
    }

    // 養育費
    calcChildSupport() {
      if (this.incomeOblication === 0 || this.incomeRight === 0) {
        return 0;
      }
      var annualIncomeOblication = this.calcBacicAnnualIncome(this.incomeOblication, (this.incomeKindOblication === INCOME_KIND_SALARY));

      var annualIncomeRight = this.calcAnnualIncomeRightOtherChildren(this.calcBacicAnnualIncome(this.incomeRight, (this.incomeKindRight === INCOME_KIND_SALARY)));
      var childIndex = this.calcChildIndex(this.childArray);
      var parentIndex = INDEX_PARENT;
      if (this.remarriage === true) {
        parentIndex += INDEX_REMARRIAGE;
      }
      var childSupportCost = annualIncomeOblication
        * childIndex / (parentIndex + childIndex)
        * annualIncomeOblication / (annualIncomeOblication + annualIncomeRight);

      // 1ヶ月分にして四捨五入
      return Math.round(childSupportCost / 12);
    }

    // 養育費（上）
    calcResultUpper() {
      var result = this.calcChildSupport();
      if (result === 0) {
        return 0;
      }
      return result + 1;
    }

    // 養育費（下）
    calcResultLower() {
      var result = this.calcChildSupport();
      if (result === 0) {
        return 0;
      }
      return result - 1;
    }


    // section 2
    husbandName: string = '';
    husbandAdress: string = '';

    wifeName: string = '';
    wifeAdress: string = '';

    documentChildCount1: number = 1;
    documentChildArray1 = [{name: null, birthDay: null}];

    updateDocumentChildCount1() {
      this.documentChildArray1 = [];
      if (!this.documentChildCount1) {
        this.documentChildCount1 = 0;
      }
      for (var i = 0; i < this.documentChildCount1; i++) {
        this.documentChildArray1.push({name: null, birthDay: null});
      }
    }

    addDocumentChildCount1() {
      this.documentChildCount1++;
      if (this.documentChildCount1 > 0) {
        this.documentChildArray1.push({name: null, birthDay: null});
      }
    }

    minusDocumentChildCount1() {
      if (this.documentChildCount1 > 0) {
        this.documentChildCount1--;
        this.documentChildArray1.splice(-1, 1);
      }
    }

    documentChildCount2: number = 1;
    documentChildArray2 = [{name: null, birthDay: null}];

    updateDocumentChildCount2() {
      this.documentChildArray2 = [];
      if (!this.documentChildCount2) {
        this.documentChildCount2 = 0;
      }
      for (var i = 0; i < this.documentChildCount2; i++) {
        this.documentChildArray2.push({name: null, birthDay: null});
      }
    }

    addDocumentChildCount2() {
      this.documentChildCount2++;
      if (this.documentChildCount2 > 0) {
        this.documentChildArray2.push({name: null, birthDay: null});
      }
    }

    minusDocumentChildCount2() {
      if (this.documentChildCount2 > 0) {
        this.documentChildCount2--;
        this.documentChildArray2.splice(-1, 1);
      }
    }

    addChildVisitaionTime() {
      this.doc.childVisitaionTime++;
    }

    minusChildVisitaionTime() {
      if (this.doc.childVisitaionTime > 0) {
        this.doc.childVisitaionTime--;
      }
    }

    onClick() {
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      pdfMake.fonts = {
        GenShinGothic: {
          normal: 'GenShinGothic-Normal-Sub.ttf'
        }
      }
      var docDefinition = {
        footer: function (currentPage) {
          return {
            columns: [
              {
                text: currentPage.toString(),
                alignment: 'center'
              }
            ]
          };
        },
        content: [
          {
            text: '離婚協議書',
            style: 'header',
            alignment: 'center'
          },
          {
            text: ' ',
            alignment: 'center'
          },
          {
            text: ' ',
            alignment: 'center'
          },
          {
            text: '夫 ' + this.textFormat(this.doc.husbandName) + '(以下、「甲」という。)と妻 ' + this.textFormat(this.doc.wifeName) + '(以下、「乙」という。)は、離婚につき協議した結果、下記の通り合意した。',
            alignment: 'center'
          },
          {
            text: ' ',
            alignment: 'center'
          },
          {
            text: '記',
            alignment: 'center'
          },
          {
            text: '(協議離婚)'
          },
          {
            text: '第1条'
          },
          {
            text: '甲と乙は、甲乙間の子 ' + this.getChildText() + 'と定めて協議離婚することに合意し、離婚届用紙に所要事項を記載し署名押印のうえ、その届出を' + this.doc.submitter + 'に託し、' + this.doc.submitter + 'は、直ちにその届出をする。'
          },
          {
            text: ' ',
            alignment: 'center'
          },
          {
            text: '(養育費)'
          },
          {
            text: '第2条'
          },
          {
            text: this.getCalcCostFordoc()
          },
          {
            text: ' ',
            alignment: 'center'
          },
          {
            text: '(特別の出費)'
          },
          {
            text: '第3条'
          },
          {
            text: this.getChildSupportPayer() + 'は、' + this.getChildSimbol() + 'の進学・事故・傷病等、特段の事由により発生する費用が過大になり、通常の養育費の額を大幅に上回る場合、' + this.getChildSupportGetter() + 'の申し出により、甲乙協議の上、別途その必要費用の全部又は一部を負担する。'
          },
          {
            text: ' ',
            alignment: 'center'
          },
        ],
        defaultStyle: {
          font: 'GenShinGothic'
        },
        styles: {
          header: {
            fontSize: 18
          }
        }
      }

      var clauseNumber = 3;

      // 面会交流
      if (this.visitaionShow == true) {
        clauseNumber += 1;
        var num = clauseNumber;
        docDefinition.content.push(
          {
            text: '(面会交流)'
          },
          {
            text: '第' + num + '条'
          },
          {
            text: '第1条の離婚届出が受理されたときは、' + this.getChildSupportGetter() + 'は、' + this.doc.childSupportPayer + 'に対し、' + this.doc.childSupportPayer + 'が丙と毎月' + this.textFormat(this.doc.childVisitaionTime) + '回程度面会交流することを認める。面会の日時、場所、及び方法等は、子らの福祉に慎重に配慮し、甲及び乙が協議して定める。'
          },
          {
            text: ' ',
            alignment: 'center'
          },
        );
      }

      // 財産分与（不動産）
      if (this.propertyRealEstateShow == true) {
        clauseNumber += 1;
        var num = clauseNumber;
        docDefinition.content.push(
          {
            text: '(不動産の財産分与)'
          },
          {
            text: '第' + num + '条'
          },
          {
            text: '第1条の離婚届出が受理されたときは、' + this.getRealEstatePay() + 'は、' + this.doc.realEstateGetter + 'に対し、財産分与として、別紙物件目録記載の建物及び土地(以下、「本件不動産」という。)を譲渡し、' + this.dateFormat(this.doc.realEstateDay) + 'までに、本件不動産につき、前項の財産分与を原因とする所有権移転登記手続をする。登記手続費用は、' + this.doc.realEstateFee + 'の負担とする。'
          },
          {
            text: ' ',
            alignment: 'center'
          },
        );
      }

      // 財産分与（現金・貯金）
      if (this.propertyCashShow == true) {
        clauseNumber += 1;
        var num = clauseNumber;
        docDefinition.content.push(
          {
            text: '(現金・貯金の財産分与)'
          },
          {
            text: '第' + num + '条'
          },
          {
            text: '第1条の離婚届出が受理されたときは、' + this.doc.cashPay + 'は、' + this.getCashGetter() + 'に対し、財産分与として、金' + this.textFormat(this.doc.cash, true) + '円を、' + this.dateFormat(this.doc.cashPayDay) + '限り、' + this.getCashGetter() + '名義の' + this.textFormat(this.doc.bankName3) + '銀行 ' + this.textFormat(this.doc.branchName3) + '支店 普通預金口座(口座番号' + this.textFormat(this.doc.accountNumber3) + ' )に振り込む方法により支払う。振込手数料は、' + this.doc.cashPayFee + 'の負担とする。'
          },
          {
            text: ' ',
            alignment: 'center'
          },
        );
      }

      // 慰謝料
      if (this.consolationShow == true) {
        clauseNumber += 1;
        var num = clauseNumber;
        docDefinition.content.push(
          {
            text: '(慰謝料)'
          },
          {
            text: '第' + num + '条'
          },
          {
            text: '第1条の離婚届出が受理されたときは、 ' + this.doc.consolationFeePayer + ' は、' + this.consolationFeeGetter() + 'に対し、慰謝料として、金' + this.textFormat(this.doc.consolationFee, true) + '円を、' + this.dateFormat(this.doc.consolationDate) + '限り、' + this.consolationFeeGetter() + '名義の ' + this.textFormat(this.doc.bankName2) + '銀行 ' + this.textFormat(this.doc.branchName2) + '支店 普通預金口座(口座番号' + this.textFormat(this.doc.accountNumber2) + ')に振り込む方法により支払う。振込手数料は、' + this.doc.consolationFeeCommissionPayer + 'の負担とする。'
          },
          {
            text: ' ',
            alignment: 'center'
          },
        );
      }

      // 最後
      docDefinition.content.push(
        {
          text: '(年金分割)'
        },
        {
          text: '第' + (clauseNumber + 1) + '条'
        },
        {
          text: '甲と乙との間の別紙情報通知書記載の情報に係る年金分割についての請求すべき按分割合を、0.5と定める。'
        },
        {
          text: ' ',
          alignment: 'center'
        },
        {
          text: '(清算条項)'
        },
        {
          text: '第' + (clauseNumber + 2) + '条'
        },
        {
          text: '甲と乙は、甲乙間には、本協議書に定めたもののほかには、何ら債権債務がないことを相互に確認する。'
        },
        {
          text: ' ',
          alignment: 'center'
        },
        {
          text: '(強制執行の認諾)'
        },
        {
          text: '第' + (clauseNumber + 3) + '条'
        },
        {
          text: '甲及び乙は、本合意につき、強制執行認諾約款付公正証書を作成することを承諾した。'
        },
        {
          text: ' ',
          alignment: 'center'
        },
        {
          text: ' ',
          alignment: 'center'
        },
        {
          text: ' ',
          alignment: 'center'
        },
        {
          text: ' ',
          alignment: 'center'
        },
        {
          text: '(甲)'
        },
        {
          text: '氏名 : ' + this.textFormat(this.doc.husbandName)
        },
        {
          text: '住所 : ' + this.textFormat(this.doc.husbandAdress)
        },
        {
          text: ' ',
          alignment: 'center'
        },
        {
          text: ' ',
          alignment: 'center'
        },
        {
          text: '(乙)'
        },
        {
          text: '氏名 : ' + this.textFormat(this.doc.wifeName)
        },
        {
          text: '住所 : ' + this.textFormat(this.doc.wifeAdress)
        },
      );

      // pdfMake.createPdf(docDefinition).download('離婚協議書.pdf');
      pdfMake.createPdf(docDefinition).open();
    }

    getChildSupportPayer() {
      var text = '【未入力】';;
      if (this.documentChildArray1.length > 0) {
        text = '乙'
      }
      if (this.documentChildArray2.length > 0) {
        text = '甲'
      }
      if (this.documentChildArray1.length > 0 && this.documentChildArray2.length > 0) {
        text = '双方'
      }
      return text;
    }

    getChildSupportGetter() {
      var text = '【未入力】';;
      if (this.documentChildArray1.length > 0) {
        text = '甲'
      }
      if (this.documentChildArray2.length > 0) {
        text = '乙'
      }
      if (this.documentChildArray1.length > 0 && this.documentChildArray2.length > 0) {
        text = '双方'
      }
      return text;
    }

    getDounin() {
      var childSum = this.documentChildArray1.length + this.documentChildArray2.length;
      if (childSum == 1) {
        return '同人が';
      }
      return '同人らが各々';
    }

    getDouninKou() {
      var childSum = this.documentChildArray1.length;
      if (childSum == 1) {
        return '同人が';
      }
      return '同人らが各々';
    }

    getDouninOtu() {
      var childSum = this.documentChildArray2.length;
      if (childSum == 1) {
        return '同人が';
      }
      return '同人らが各々';
    }

    consolationFeeGetter() {
      if (this.doc.consolationFeePayer == '甲') {
        return '乙'
      } else {
        return '甲'
      }
    }

    getCashGetter() {
      if (this.doc.cashPay == '甲') {
        return '乙'
      } else {
        return '甲'
      }
    }

    getRealEstatePay() {
      if (this.doc.realEstateGetter == '甲') {
        return '乙'
      } else {
        return '甲'
      }
    }

    simbol = ['丙', '丁', '戊', '己', '庚', '辛', '壬', '癸'];

    getChildText() {
      var text = '';
      this.documentChildArray1.forEach(function (child, index) {
        text += this.textFormat(child.name) + '(' + this.dateFormat(child.birthDay) + '生、以下「' + this.simbol[index] + '」という。) の親権者を甲、';
      }, this);
      this.documentChildArray2.forEach(function (child, index, sim) {
        text += this.textFormat(child.name) + '(' + this.dateFormat(child.birthDay) + '生、以下「' + this.simbol[this.documentChildArray1.length + index] + '」という。) の親権者を乙、';
      }, this);
      return text;
    }

    getChildSimbol() {
      var text = '';
      this.documentChildArray1.forEach(function (child, index) {
        text += this.simbol[index];
        if (index != (this.documentChildArray1.length - 1)) {
          text += '、';
        }
      }, this);
      this.documentChildArray2.forEach(function (child, index) {
        if (index == 0 && this.documentChildArray1.length != 0) {
          text += '、';
        }
        text += this.simbol[this.documentChildArray1.length + index];
        if (index != (this.documentChildArray1.length - 1)) {
          text += '、';
        }
      }, this);
      return text;
    }

    getChildSimbolKou() {
      var text = '';
      this.documentChildArray1.forEach(function (child, index) {
        text += this.simbol[index];
        if (index != (this.documentChildArray1.length - 1)) {
          text += '、';
        }
      }, this);
      return text;
    }

    getChildSimbolOtu() {
      var text = '';
      this.documentChildArray2.forEach(function (child, index) {
        text += this.simbol[this.documentChildArray1.length + index];
        if (index != (this.documentChildArray1.length - 1)) {
          text += '、';
        }
      }, this);
      return text;
    }

    getCalcCostFordoc() {

      var text;

      if (this.documentChildArray1.length != 0 && this.documentChildArray2.length == 0) {
        text = '第1条の離婚届出が受理されたときは、'
          + '乙は甲に対し、' + this.getChildSimbol() + 'の養育費として、'
          + this.dateFormat(this.doc.paymentStartDate1) + 'から'
          + this.getDounin() + '満20歳に達する日の属する月まで、1カ月金' + this.textFormat(this.doc.childSupport1, true) + '円を、毎月末日限り、'
          + '甲名義の'
          + this.textFormat(this.doc.bankName1) + '銀行 '
          + this.textFormat(this.doc.branchName1) + '支店 普通預金口座(口座番号'
          + this.textFormat(this.doc.accountNumber1) + ' )に振り込む方法により支払う。振込手数料は、'
          + this.doc.childSupportTransferFee1 + 'の負担とする。';
      } else if (this.documentChildArray2.length != 0 && this.documentChildArray1.length == 0) {
        text = '第1条の離婚届出が受理されたときは、'
          + '甲は乙に対し、' + this.getChildSimbol() + 'の養育費として、'
          + this.dateFormat(this.doc.paymentStartDate2) + 'から'
          + this.getDounin() + '満20歳に達する日の属する月まで、1カ月金' + this.textFormat(this.doc.childSupport2, true) + '円を、毎月末日限り、'
          + '乙名義の'
          + this.textFormat(this.doc.bankName2) + '銀行 '
          + this.textFormat(this.doc.branchName2) + '支店 普通預金口座(口座番号'
          + this.textFormat(this.doc.accountNumber2) + ' )に振り込む方法により支払う。振込手数料は、'
          + this.doc.childSupportTransferFee2 + 'の負担とする。';
      } else {
        text = '第1条の離婚届出が受理されたときは、'
          + '乙は甲に対し、' + this.getChildSimbolKou() + 'の養育費として、'
          + this.dateFormat(this.doc.paymentStartDate1) + 'から'
          + this.getDouninKou() + '満20歳に達する日の属する月まで、1カ月金' + this.textFormat(this.doc.childSupport1, true) + '円を、毎月末日限り、'
          + '甲名義の'
          + this.textFormat(this.doc.bankName1) + '銀行 '
          + this.textFormat(this.doc.branchName1) + '支店 普通預金口座(口座番号'
          + this.textFormat(this.doc.accountNumber1) + ' )に振り込む方法により支払い、'
          + '甲は乙に対し、' + this.getChildSimbolOtu() + 'の養育費として、'
          + this.dateFormat(this.doc.paymentStartDate2) + 'から'
          + this.getDouninOtu() + '満20歳に達する日の属する月まで、1カ月金' + this.textFormat(this.doc.childSupport2, true) + '円を、毎月末日限り、'
          + '乙名義の'
          + this.textFormat(this.doc.bankName2) + '銀行 '
          + this.textFormat(this.doc.branchName2) + '支店 普通預金口座(口座番号'
          + this.textFormat(this.doc.accountNumber2) + ' )に振り込む方法により支払う。振込手数料は、各々の負担とする。'
      }

      return text;
    }

    textFormat(text, hasTax = false) {
      if (text == null) {
        return '【未入力】';
      }

      // 大文字を半角に変換
      var numPyphen = function (str, tax) {
        if (hasTax && Number(str)) {
          return parseInt(str) + parseInt(str) * parseFloat(tax);
        }
        if (str == null || typeof str !== "string") {
          return str;
        }
        return str.replace(/[０-９]/g, function (s) {
          return String.fromCharCode(s.charCodeAt(0) - 65248);
        }).replace(/[‐－―ー]/g, '-');
      };
      var after = numPyphen(text, this.env.tax);
      return after;
    }

    dateFormat(date) {
      if (date == null || date === "") {
        return '【未入力】';
      }
      return date.get('year') + '年' + (date.get('month') + 1) + '月' + date.get('date') + '日'
    }
}
